import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import SelectCards01 from 'components/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/WPS/SelectCards01';
import SelectCards02 from 'components/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/WPS/SelectCards02';
import NavButtons from 'components/Outdoor_Cameras/IN-9408_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9408 WQHD Quick Installation",
  "path": "/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/WPS/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "IN-9408 WQHD Quick Installation",
  "image": "../../../P_SearchThumb_IN-9008HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-9408WQHD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-9408 WQHD Quick Installation' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='IN-9408 WQHD Quick Installation' image='/images/Search/P_SearchThumb_IN-9408HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-9408HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9408_WQHD/Schnell_Installation/PoE_or_WiFi/WPS/' locationFR='/fr/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/WPS/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <Link to="/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/" mdxType="Link"><EuiButton color="danger" fill mdxType="EuiButton">Return to Section II</EuiButton></Link>
    <h2 {...{
      "id": "ii-e-1-activate-wps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-e-1-activate-wps",
        "aria-label": "ii e 1 activate wps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-e-1 Activate WPS`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01a37c995f7741ea5e90566c3376b02d/99072/IN-9008-Quick-Installation-II-E-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMUlEQVQoz6VSy3LDIAz0//9jDpnJwUltQOIhhATu1HLTNJ3pY7oXGNgVrLRT7z3nzMxExMzbXzCJyLpjnmfn3G80Y4ze+xhj+p4kIk+HIkJE3vuc82Qkw7ZtqirvaK19NSIizOy9jyk9v5xSCjsgBOecia1ia01ViSilZPtpPEBV7f07WmtjjJQSIsYYrW4pxWhvDTMNALzsEJFaK3gfEYnIeLzDKjJz7/0Q2xEAXC6X8/nsvUfE0+k0z3MIYVmWNxcAiFhr/SRW1ZRSKcV467JEgBjj9XrNOSPi7XZzzoUQELGUsm1brfX4tlWy1h8WVCuzeVFVSwEArOtqQWqtHSG522DmY0S7UkTsb/fOlVJ4v/pImC1EFPbZxBgREQC890T0Qzy3f2B6DON9yI/7b8SvHeY1C5xy5sUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01a37c995f7741ea5e90566c3376b02d/e4706/IN-9008-Quick-Installation-II-E-1.avif 230w", "/en/static/01a37c995f7741ea5e90566c3376b02d/d1af7/IN-9008-Quick-Installation-II-E-1.avif 460w", "/en/static/01a37c995f7741ea5e90566c3376b02d/899b7/IN-9008-Quick-Installation-II-E-1.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01a37c995f7741ea5e90566c3376b02d/a0b58/IN-9008-Quick-Installation-II-E-1.webp 230w", "/en/static/01a37c995f7741ea5e90566c3376b02d/bc10c/IN-9008-Quick-Installation-II-E-1.webp 460w", "/en/static/01a37c995f7741ea5e90566c3376b02d/6366e/IN-9008-Quick-Installation-II-E-1.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01a37c995f7741ea5e90566c3376b02d/81c8e/IN-9008-Quick-Installation-II-E-1.png 230w", "/en/static/01a37c995f7741ea5e90566c3376b02d/08a84/IN-9008-Quick-Installation-II-E-1.png 460w", "/en/static/01a37c995f7741ea5e90566c3376b02d/99072/IN-9008-Quick-Installation-II-E-1.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01a37c995f7741ea5e90566c3376b02d/99072/IN-9008-Quick-Installation-II-E-1.png",
              "alt": "IN-9408 WQHD Quick Installation",
              "title": "IN-9408 WQHD Quick Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Connect the WiFi antenna and make sure that the antenna cable is still connected (see step #3 `}<Link to="/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/" mdxType="Link">{`Here`}</Link>{`). Now plug in the power supply and wait for the camera to boot. Press the Reset/WPS button for 3s to activate the WPS function (Caution: pressing it for 10s will reset your camera!).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards01 mdxType="SelectCards01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "ii-e-6-internet-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-e-6-internet-access",
        "aria-label": "ii e 6 internet access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-e-6 Internet Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgklEQVQoz5WRMUsDMRiG465ODk4OjlIUXEUQcRD8A+JPEEdB0MEOCqJgf4EKjvobFMRBFIoiKii9WrXXS3I52ssll0u8JNIWsUpr9eVZ8pE33/e9AQMr5xO5/OjWVWbzcjh7MZnL9y+fgcWTnqVT0JXB7M147mFs92Fk+25o4zazcz+wftO7et231h0wd1iZP8ILx/7sIZw+8Kb2K9N77sz+nwAwqD1XcLGMSi58g/4bJCK1SQux0p0A70oySlkU0TDkjLEokolokohYykRrbTsIaK1jzkkQYJ9UqzUhBEKo9PLqebBcdiFCQsRKqbZPAGOM1hph7PskSaS1lhC/UHCkUsaYNE1VQ7+ZIUTVWq1ZklIKIUznab/M1lglpVMshmEYUoowdl23bjam9d6P47fOPiEehDRi1togCBzHQQjzeoCsEWLEOW9n1toaE4aUECKSxFobc+55HsaYUso+1X7n+thKPj49McbsP1X/qjRNKaVCCK11c4umTDeBv6TaqfMHPlPbzBGNPJIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/e4706/IN-9008-Quick-Installation-1-16.avif 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/d1af7/IN-9008-Quick-Installation-1-16.avif 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/899b7/IN-9008-Quick-Installation-1-16.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/a0b58/IN-9008-Quick-Installation-1-16.webp 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/bc10c/IN-9008-Quick-Installation-1-16.webp 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/6366e/IN-9008-Quick-Installation-1-16.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/81c8e/IN-9008-Quick-Installation-1-16.png 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/08a84/IN-9008-Quick-Installation-1-16.png 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png",
              "alt": "IN-9408 WQHD Quick Installation",
              "title": "IN-9408 WQHD Quick Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`You need to `}<Link to="/Internet_Access/Port_Forwarding/" mdxType="Link">{`Forward a Port`}</Link>{` inside your router, if you want to access your camera over it´s `}<Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link">{`DDNS Address`}</Link>{` from the internet. Alternatively, you can use the `}<Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/" mdxType="Link">{`P2P User ID`}</Link>{` to access your camera with our smartphone app (see Step 12 below).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards02 mdxType="SelectCards02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "ii-e-9-reassemble",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-e-9-reassemble",
        "aria-label": "ii e 9 reassemble permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-e-9 Reassemble`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/15c903ad5b460d58a5178b4fe80b9dbf/99072/IN-9008-Quick-Installation-II-E-9.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz51S0W7DIAzM/39f31tVbaUUEsBAMAbDlLpKo61TtfkhD7HPnO9uYGYiKqUQETP3v9QQQhjHUSl1vV5DCFujtVZK+QD+rdFayzn/E9x7J6IP4LarWmtrbeu9pb0fGEopggGAaZqMMcy8LItzDgBkVPbWWmOMABBjlP/Dtt45d7lcTqcTACilDoeDUqrW2nuf5/l4PBpjYozLsqSUEHEF11pDCIhojFFKaa2NMQBwu9201jlnIprn2VobY/TeW2tTSjHGp2CIqLX23hNRRsyI1tppmgBAeKWU7uP9fD5770MI3vvny8L5JVVrXaKyE2ZVPueMa4UQAOBllejxzNljqJTSHtfuVd7WEREi1lpXMDPLMfKVq7ZRZnbOOWvh0ZLkiPhvQsLMQkG4iOHi6Lf8D5v1m6UAIIJba/dp/5mwLxAqNPZ/u1yPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15c903ad5b460d58a5178b4fe80b9dbf/e4706/IN-9008-Quick-Installation-II-E-9.avif 230w", "/en/static/15c903ad5b460d58a5178b4fe80b9dbf/d1af7/IN-9008-Quick-Installation-II-E-9.avif 460w", "/en/static/15c903ad5b460d58a5178b4fe80b9dbf/899b7/IN-9008-Quick-Installation-II-E-9.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/15c903ad5b460d58a5178b4fe80b9dbf/a0b58/IN-9008-Quick-Installation-II-E-9.webp 230w", "/en/static/15c903ad5b460d58a5178b4fe80b9dbf/bc10c/IN-9008-Quick-Installation-II-E-9.webp 460w", "/en/static/15c903ad5b460d58a5178b4fe80b9dbf/6366e/IN-9008-Quick-Installation-II-E-9.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15c903ad5b460d58a5178b4fe80b9dbf/81c8e/IN-9008-Quick-Installation-II-E-9.png 230w", "/en/static/15c903ad5b460d58a5178b4fe80b9dbf/08a84/IN-9008-Quick-Installation-II-E-9.png 460w", "/en/static/15c903ad5b460d58a5178b4fe80b9dbf/99072/IN-9008-Quick-Installation-II-E-9.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/15c903ad5b460d58a5178b4fe80b9dbf/99072/IN-9008-Quick-Installation-II-E-9.png",
              "alt": "IN-9408 WQHD Quick Installation",
              "title": "IN-9408 WQHD Quick Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Fasten the mainboard slide and use the 4 screws to mount the back plate back onto your camera casing. Screw the wall mount to the back of the camera.`}</p>
    <h2 {...{
      "id": "ii-e-10-final-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-e-10-final-installation",
        "aria-label": "ii e 10 final installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-e-10 Final Installation`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6195daaf7617320ae7e5e1096caf3538/99072/IN-9008-Quick-Installation-II-E-10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABGElEQVQoz5WS0W6DMAxF8/9/WXigtE1pMDF27DRT4y5CbEzdeUABcciNL05VU0oiwhVbl89wMcZxHKdpul6vl8tlHEfv/ady+SeWlJkR0T0rpZS2KKXknI/kVAkh3G6333cmoiPfZEQMIbijN1qKn7ERUURyzq4FbrFVlZn/OHZrxOWcRUQrIgIAc4WIYozLsqSUdrJUXvK2VZvEMAyn08l7v64rESGiqh7K7XgppcfjEUIAAERclgUAmJmIdrJ97hXbBqiqRAQA9/vdez9N01KZ53mXnJmtC2cTAoD4ja/YE7vuzszMFta1v8L23zaUVdcY2TI/n69Wck5EvK5lK2+rstu3wCyI574fum7ounPfz94X5lIH9gUnYTfjHr/8TAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6195daaf7617320ae7e5e1096caf3538/e4706/IN-9008-Quick-Installation-II-E-10.avif 230w", "/en/static/6195daaf7617320ae7e5e1096caf3538/d1af7/IN-9008-Quick-Installation-II-E-10.avif 460w", "/en/static/6195daaf7617320ae7e5e1096caf3538/899b7/IN-9008-Quick-Installation-II-E-10.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6195daaf7617320ae7e5e1096caf3538/a0b58/IN-9008-Quick-Installation-II-E-10.webp 230w", "/en/static/6195daaf7617320ae7e5e1096caf3538/bc10c/IN-9008-Quick-Installation-II-E-10.webp 460w", "/en/static/6195daaf7617320ae7e5e1096caf3538/6366e/IN-9008-Quick-Installation-II-E-10.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6195daaf7617320ae7e5e1096caf3538/81c8e/IN-9008-Quick-Installation-II-E-10.png 230w", "/en/static/6195daaf7617320ae7e5e1096caf3538/08a84/IN-9008-Quick-Installation-II-E-10.png 460w", "/en/static/6195daaf7617320ae7e5e1096caf3538/99072/IN-9008-Quick-Installation-II-E-10.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6195daaf7617320ae7e5e1096caf3538/99072/IN-9008-Quick-Installation-II-E-10.png",
              "alt": "IN-9408 WQHD Quick Installation",
              "title": "IN-9408 WQHD Quick Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Install your camera and plug in the power supply. The camera should automatically reconnect itself to your WiFi network.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      